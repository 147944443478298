import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { trackPageView } from 'src/utils/analytics';

import Layout from 'src/components/Layout';
import Page from 'src/components/Page';

const HomePage = ({ data }) => {
  useEffect(() => trackPageView(), []);

  return (
    <Layout isNavTransparent={data.home.isNavTransparent}>
      <Page components={data.home.components} />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default HomePage;

export const pageQuery = graphql`
  query {
    home: contentfulPageTemplate(slug: { eq: "home" }) {
      isNavTransparent
      ...PageTemplateFragment
    }
  }
`;
